import React from "react";
import { Route, Redirect } from "react-router-dom";

// import action
import { isLogin } from '../../actions/auth'

const ConditionRoute = ({ component: Component, type }) => {

    return (
        <Route
            render={props => {
                if (type == 'auth' && isLogin() == true) {
                    return <Redirect to="/profile" />
                } else if (type == 'private' && isLogin() != true) {
                    return <Redirect to="/login" />
                }

                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;