let key = {};
if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config")

    key = {
        URL: {
            API: "https://troniextechnologies.com/",
            IMAGE: "https://troniextechnologies.com/"
        },
        SECRET_KEY: {
            JWT: 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
        },
    };
} else {
    console.log("Set Development Config")
    key = {
        URL: {
            API: "http://localhost:2053",
            IMAGE: "http://localhost:2053"
        },
        SECRET_KEY: {
            JWT: 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
        },
    };
}

export default {
    ...key,
    ...{ SITE_DETAIL: require('./siteConfig') }
};