export const getAuthToken = () => {
    if (localStorage.getItem('admin_token')) {
        return localStorage.getItem('admin_token')
    }
    return '';
}

export const removeAuthToken = () => {
    localStorage.removeItem('admin_token');
}

export const setAuthToken = (token) => {
    localStorage.setItem('admin_token', token);
    return true
}